<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/booking">
        {{ $t("reservationAndUsageHistory.reservationAndUsageHistory") }}
      </router-link>
      >
      <router-link
        :to="{
          path: '/home/booking/reservation-details/' + id + '/',
          params: { id }
      }"
      >
        {{ $t("reservationAndUsageHistory.reservationDetails") }}
      </router-link>
      >
      <router-link
        v-if="!onReservationDetailPage"
        :to="{
          path: '/home/booking/reservation-details/' + id + '/reservation-change',
          params: { id }
      }"
      >
        {{ $t("reservationAndUsageHistory.reservationChange") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container pb-15 pt-5" v-if="booking">
      <UsageHistoryItem
        :id="booking.id"
        :urlImg="facility.mainImageUrl"
        :title="facility.name"
        :checkInDate="booking.checkInDate"
        :checkOutDate="booking.checkOutDate"
        :roomTypeName="booking.room.roomType.name"
        isActive
        :cancelledAt="cancelledAt"
      />
      <router-view :btn="true" :originalBack="originalBack" :setBackButtonAction="setBackButtonAction"></router-view>
      <div class="px-3">
        <v-btn
          elevation="0"
          rounded
          block
          outlined
          x-large
          class="text-title bg-white --prm mb-3"
          @click="back"
        >
          {{ $t("reservationAndUsageHistory.return") }}
        </v-btn>
      </div>
    </div>
    <ReturnTopPage
      :visible="changeCompleted"
      @close="returnTopClose"
    >
      <div class="text-left text-medium --c-medium-gray pb-5 px-12">
        <span class="font-weight-bold">{{ $t('text.changedMyReservation') }}</span>
        <div v-if="changeMessage" v-html="changeMessage"  class="mt-2">
        </div>
      </div>
      <template v-slot:buttons>
        <v-btn
          v-for="btn, ind in changeButtons"
          :key="ind"
          rounded
          width="262"
          height="57"
          color="primary"
          class="font-weight-bold my-1"
          @click="btn.click"
        >
          {{ btn.text }}
        </v-btn>
      </template>
    </ReturnTopPage>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import UsageHistoryItem from '@/components/UsageHistoryItem'
import ReturnTopPage from '../../components/ReturnTopPage.vue'
import selectedBookingMixin from '../../selected-booking.mixin'

export default {
  name: 'AccommodationDateExtension',
  mixins: [selectedBookingMixin],
  components: {
    Breadcrumbs,
    UsageHistoryItem,
    ReturnTopPage
  },
  data () {
    return {
      completed: false,
      backAction: null
    }
  },
  computed: {
    onReservationDetailPage () {
      return !!this.$route.meta.onReservationDetailPage
    }
  },
  methods: {
    setBackButtonAction (action) {
      this.backAction = action
    },
    back () {
      if (this.backAction) {
        this.backAction()
      } else {
        this.originalBack()
      }
    },
    originalBack () {
      this.$router.push({
        name: 'home-booking-reservation-details-detail',
        params: { id: this.id.toString() }
      })
    },
    returnTopClose () {
      this.completeChange({ completed: false })
      this.$router.push({
        name: this.onReservationDetailPage ? 'home-booking-reservation-details-detail' : 'home-booking-reservation-details-detail-reservation-change',
        params: { id: this.id }
      })
    }
  },
  mounted () {
    this.completeChange({ completed: false })
  }
}
</script>
